$(document).ready(function() {
    $('.product-thumbnail').hover(function() {
        $('img#product-preview').attr('src', $(this).data('picture-url'));
        $("div#gallery-background img")
            .attr('src', $(this).data('picture-xl-url'));
    });

    $('.product-thumbnail').on('click dblclick', function(e) {
        $('img#product-preview').attr('src', $(this).data('picture-url'));
        $("div#gallery-background img")
            .attr('src', $(this).data('picture-xl-url'));
        $('div#gallery-background').fadeIn();
    });

    $('img#product-preview').on('click dblclick', function(e) {
        e.preventDefault();
        $('div#gallery-background').fadeIn();
    });

    // Close image preview on click
    $('div#gallery-background').click(function(e) {
        $(this).fadeOut();
    });

    // Close image preview on ESC
    $(document).keyup(function(e) {
        if (e.keyCode === 27) {
            $('div#gallery-background').fadeOut();
        }
    })
});